<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-menu-diner_00000045587388601638311360000015202079585504147587_" x1="152.3368" x2="32.3168" y1="143.532" y2="42.822">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M57.4,78.8  h-8.5V49.1h-8.5v29.7h-8.5V49.1h-8.5v29.7c0,8.9,7,16.3,15.9,16.8v38.3H50V95.6c8.9-0.5,15.9-7.9,15.9-16.8V49.1h-8.5V78.8z   M138.4,66.1V100H149v33.9h10.6V49.1C147.8,49.1,138.3,58.6,138.4,66.1L138.4,66.1z M123,105.4c-1.7,0.4-3.4,0.7-5.1,0.7  c-5.2,0-10.1-2-13.8-5.7c-4.9-4.9-6.8-12.1-5-18.8c0.4-1.3-0.4-2.6-1.7-3c-0.4-0.1-0.9-0.1-1.3,0c-13,3.5-20.8,16.8-17.3,29.8  s16.8,20.8,29.8,17.3c8.5-2.2,15.1-8.8,17.3-17.3c0.4-1.3-0.4-2.6-1.7-3C123.9,105.3,123.4,105.3,123,105.4L123,105.4z M116.2,115.9  c-7.6,7.6-20,7.7-27.6,0.1S81,96,88.6,88.4c1.5-1.5,3.2-2.7,5.1-3.7c-1.1,13.4,8.9,25.1,22.3,26.2c1.3,0.1,2.6,0.1,3.9,0  C118.9,112.7,117.7,114.4,116.2,115.9L116.2,115.9z" id="icon-menu-diner" style="fill:url(#icon-menu-diner_00000045587388601638311360000015202079585504147587_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>